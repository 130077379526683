import React, { useEffect } from 'react';
import InnerBox from './InnerBox';
import { Route, Routes } from 'react-router-dom';
import { ChatsList } from '@features/chat-list/ChatList';
import { setCredentials } from '@features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useLoginTgMutation } from '@api/apiClient';

// @ts-ignore
let tgWebApp: TelegramWebApps.WebApp | null = window.Telegram.WebApp;

const Layout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // handleTelegramId(526973831);
    if (tgWebApp) {
      if (tgWebApp.initDataUnsafe) {
        const webAppDataUnsafe = tgWebApp.initDataUnsafe;

        if (webAppDataUnsafe && webAppDataUnsafe.user && webAppDataUnsafe.user.id) {
          console.log('logging in via tg id');
          const telegramId = webAppDataUnsafe.user.id;
          handleTelegramId(telegramId);
        }
      }
    }
  }, []);

  const [loginTg] = useLoginTgMutation();
  const handleLoginTgError = () => {
    console.log('error while logging ins via tg');
  };
  async function handleTelegramId(telegramId: number) {
    if (tgWebApp) {
      // if (true) {
      await loginTg({ telegramId: telegramId })
        .unwrap()
        .then((res) => {
          dispatch(setCredentials(res));
        })
        .catch(() => {
          handleLoginTgError();
          tgWebApp = null;
        });
    }
  }

  return (
    <>
      <InnerBox>
        <Routes>
          <Route path={'/'} element={<ChatsList />} />
        </Routes>
      </InnerBox>
    </>
  );
};

export default Layout;
