export const timeToRefresh = 10000; // 60000 миллисекунд = 1 минута
export const maxChatsPageConst = 10 ** 10;

export function getHumanDate(dateString: string): String {
  const givenDate = new Date(dateString);
  const dateDay = givenDate.getDate();
  const dateMonth = givenDate.getMonth() + 1;
  const dateYear = givenDate.getFullYear();
  const dateHour = givenDate.getHours();
  const dateMinutes = givenDate.getMinutes();

  const today = new Date();
  const todayDateDay = today.getDate();
  const todayDateMonth = today.getMonth() + 1;
  const todayYear = today.getFullYear();

  if (dateDay === todayDateDay && dateMonth === todayDateMonth && dateYear === todayYear) {
    return `${dateHour > 9 ? dateHour : '0' + dateHour}:${dateMinutes > 9 ? dateMinutes : '0' + dateMinutes}`;
  }
  if (dateDay === todayDateDay - 1 && dateMonth === todayDateMonth && dateYear === todayYear) {
    return 'Вчера';
  }
  if (dateDay === todayDateDay - 2 && dateMonth === todayDateMonth && dateYear === todayYear) {
    return 'Позавчера';
  }

  return `${dateDay > 9 ? dateDay : '0' + dateDay}.${dateMonth > 9 ? dateMonth : '0' + dateMonth}.${dateYear}`;
}
