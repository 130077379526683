import React, { useRef, useState } from 'react';
import './chat.index.scss';
import { ChatHeader } from '@features/chat-list/components/ChatHeader';
import { ChatsListOnlyMy } from '@features/chat-list/ChatListOnlyMy';
import { ChatsListAll } from '@features/chat-list/ChatListAll';
import { WarnChatList } from './WarnChatList';

export const ChatsList = () => {
  const [filterMyChats, setFilterMyChats] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [searchString, setSearchString] = useState('');

  const setNeedRefreshCallback = (needRefresh: boolean) => {
    setNeedRefresh(needRefresh);
  };

  const setSearchStringCallback = (searchStringToSet: string) => {
    setSearchString(searchStringToSet);
  };
  const setFilterMyChatsCallback = (filterMyChat: boolean) => {
    setFilterMyChats(filterMyChat);
  };

  return (
    <div>
      <ChatHeader
        setterMyFilter={setFilterMyChatsCallback}
        filterMyChats={filterMyChats}
        setSearchString={setSearchStringCallback}
        setterNeedRefresh={setNeedRefreshCallback}
      />
      <WarnChatList setterNeedRefresh={setNeedRefreshCallback} needRefresh={needRefresh}></WarnChatList>
      <ChatsListAll
        setterNeedRefresh={setNeedRefreshCallback}
        needRefresh={needRefresh}
        searchString={searchString}
        filterMyChat={filterMyChats}
      />
    </div>
  );
};
