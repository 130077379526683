import React from 'react';
import Layout from './layout/Layout';
import { Box } from '@chakra-ui/react';
import { useAuth } from './app/hooks';

function App() {
  return (
    <Box>
      <Layout />
    </Box>
  );
}

export default App;
