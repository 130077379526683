import React, { useEffect, useRef, useState } from 'react';
import './chat.index.scss';
import { Box, Flex } from '@chakra-ui/react';
import { Chat } from '@features/chat-list/components/Chat';
import { useGetChatMessagesFullMutation } from '@api/apiClient';
import { APP_ROUTES, LOCAL_STORAGE } from '@common/constants';
import { FullChat, FullChatsResponse, User } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@app/hooks';
import { maxChatsPageConst, timeToRefresh } from '@features/chat-list/chat.namespace';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@features/auth/authSlice';

export const ChatsListAll = (props: {
  needRefresh: boolean;
  setterNeedRefresh: (needRefresh: boolean) => void;
  searchString: string;
  filterMyChat: boolean;
}) => {
  const [getAgentChats, { data, isLoading, error }] = useGetChatMessagesFullMutation();

  const eventScrollAndGetNewChats = () => {
    try {
      const endElem = document.querySelector('.end-of-document')!;
      const position = endElem.getBoundingClientRect();
      if (position.bottom < window.innerHeight && !loading) {
        setPage((prev) => prev + 1);
        props.setterNeedRefresh(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApiError = () => {
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS);
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT);
  };

  const user = useSelector(selectCurrentUser);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(maxChatsPageConst);
  const [agentChats, setAgentChats] = useState<FullChatsResponse | null>(null);
  const [activeAgentChat, setActiveAgentChat] = useState(0);

  const updateChats = (
    user: User | null,
    needRefresh: boolean,
    page: number,
    searchString: string,
    onlyMyChats: boolean = false,
  ) => {
    setLoading(true);
    if (user !== null && needRefresh) {
      getAgentChats({ managerId: user!.id, page, onlyMyChats: onlyMyChats, searchString: searchString })
        .unwrap()
        .then((res) => {
          if (res.chatsData.length > 0) {
            if (agentChats && agentChats.chatsData && agentChats.chatsData.length > 0 && page !== 0) {
              const newChats = JSON.parse(JSON.stringify(agentChats));
              const resNewChats = res.chatsData.filter((chat: FullChat) => {
                if (chat !== null) {
                  if (chat.chatId !== activeAgentChat) {
                    return chat;
                  }
                }
              });

              newChats.chatsData = newChats.chatsData.concat(resNewChats);
              setAgentChats(newChats);
            } else {
              setActiveAgentChat(res.userCurrentChats.manager_bot_chat_id);
              setAgentChats(res);
            }
          } else {
            setMaxPage(page);
            const btn = document.getElementsByClassName('chat__btn_next-page')[0];
            btn.classList.add('hidden');
          }
        })
        .catch(() => {
          handleApiError();
        });
    }

    setLoading(false);
    props.setterNeedRefresh(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (window.scrollY > 70) {
        return;
      }
      window.scroll(0, 0);
      setPage(0);
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateChats(user, props.needRefresh, page, props.searchString, props.filterMyChat);
  }, [props.needRefresh]);

  useEffect(() => {
    if (!props.needRefresh) props.setterNeedRefresh(true);
  }, [user]);

  useEffect(() => {
    if (!props.needRefresh) props.setterNeedRefresh(true);
  }, []);

  useEffect(() => {
    setPage(0);
    props.setterNeedRefresh(true);
    // updateChats(user, props.needRefresh, page, props.searchString, false);
  }, [props.searchString]);

  useEffect(() => {
    setPage(0);
    props.setterNeedRefresh(true);
    // updateChats(user, props.needRefresh, page, props.searchString, false);
  }, [props.filterMyChat]);

  useEffect(() => {
    props.setterNeedRefresh(true);
    // updateChats(user, props.needRefresh, page, props.searchString, false);
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', eventScrollAndGetNewChats);

    if (maxPage !== maxChatsPageConst) {
      window.removeEventListener('scroll', eventScrollAndGetNewChats);
    }

    return () => {
      window.removeEventListener('scroll', eventScrollAndGetNewChats);
    };
  }, [maxPage]);

  return (
    <Box>
      <Flex flexDirection="column" className={'chat-list'}>
        {agentChats && agentChats.chatsData && agentChats.chatsData.length > 0 ? (
          agentChats.chatsData.map((chat, index) => {
            if (chat !== null) {
              return (
                <div key={'chat-id-' + index}>
                  <Chat
                    managerId={user!.id}
                    chatId={chat.chatId}
                    clientName={`${chat.client_name} ${chat.client_last_name ? `${chat.client_last_name[0]}.` : ''}`}
                    hasunread={chat.hasunread}
                    messageText={chat.message_text}
                    projectStatus={chat.project_status}
                    chatIsClient={chat.chat_is_client}
                    isMultyChat={chat.is_multy_chat}
                    messageAuthorId={chat.message_author_id}
                    clientPhotoLink={chat.user_photos_link}
                    agentCurrentChatId={activeAgentChat ?? 0}
                    setActiveAgentChat={setActiveAgentChat}
                    clientId={chat.chat_client_id}
                    chatLastMessageAt={chat.chat_last_message_at}
                    messageunCount={chat.messageun_count}
                    agentChats={agentChats}
                    filterOnlyMyChats={false}
                    setAgentChats={setAgentChats}
                    setPage={setPage}
                    setMaxPage={setMaxPage}
                    agentsList={chat.agents_list}
                    queuedMessages={chat.queued_messages}
                    daysFromProject={chat.project_start_days_number}
                    lastMessageName={chat.message_author_name}
                  />
                </div>
              );
            }
          })
        ) : !loading ? (
          <b>Нет чатов</b>
        ) : null}

        {loading ? <b>Загрузка...</b> : null}

        <button
          className={['chat__btn_next-page', maxPage === page ? 'hidden' : ''].join(' ')}
          onClick={() => {
            setPage((prev) => prev + 1);
          }}
        >
          еще чаты
        </button>
      </Flex>

      <div className={'end-of-document'}></div>
    </Box>
  );
};
