import React, { useEffect, useState } from 'react';
import { Button, Spinner } from '@chakra-ui/react';
import { QueuedMessage } from '@api/types';

export interface CancelMessageBtnProps {
  message: QueuedMessage;
  cancel: () => void;
}

const CancelMessageBtn = (props: CancelMessageBtnProps) => {
  const { id, delay, timestamp } = props.message;
  const [remained, setRemained] = useState(Math.round((delay - (new Date().getTime() - timestamp)) / 1000));
  const [isCanceling, setIsCanceling] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const remained = Math.round((delay - (new Date().getTime() - timestamp)) / 1000);
      setRemained(Math.max(remained - 1, 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [id, timestamp, delay]);

  useEffect(() => {
    setIsCanceling(false);
  }, [id]);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    props.cancel();
    setIsCanceling(true);
  };

  return (
    <Button
      colorScheme="blue"
      variant="outline"
      size="xs"
      ml={1}
      onClick={handleCancel}
      isActive={!isCanceling}
      isDisabled={isCanceling}
      width="90px"
      className={'button-cancel-message'}
    >
      {isCanceling ? <Spinner size="xs" colorScheme="blue" /> : `${remained}с • Отмена`}
    </Button>
  );
};

export default CancelMessageBtn;
