import './chat.index.scss';
import { Flex } from '@chakra-ui/react';
import { WarnChat } from './components/WarnChat';
import { useNavigate } from 'react-router-dom';
import { useEnterChatMutation, useGetWarnClientsMutation } from '@api/apiClient';
import { WarnClientsResponse } from '@api/types';
import { useEffect, useRef, useState } from 'react';
import { timeToRefresh } from './chat.namespace';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@features/auth/authSlice';

// @ts-ignore
const tgWebApp: TelegramWebApps.WebApp = window.Telegram.WebApp;

export const WarnChatList = (props: { needRefresh: boolean; setterNeedRefresh: (needRefresh: boolean) => void }) => {
  const navigate = useNavigate();
  // const user = useAuth();
  const user = useSelector(selectCurrentUser);
  const [getWarnClients, { data, isLoading, error }] = useGetWarnClientsMutation();
  const [warnClients, setWarnClients] = useState<WarnClientsResponse | null>(null);
  const [enterChat] = useEnterChatMutation();
  const [userChanged, setUserChanged] = useState<boolean>(false);

  useEffect(() => {
    setUserChanged(true);
  }, [user]);

  const loading = useRef(true);

  const enterTgChat = async (chatId: number, newClientId: number = 0) => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isIframe = window.self !== window.top;

    await enterChat({
      chatId: chatId,
      managerId: user!.id,
      newClientId: newClientId,
    })
      .then(async () => {
        props.setterNeedRefresh(true);
      })
      .catch(() => {
        handleApiError();
      });
    if (isTouchDevice || isIframe) {
      tgWebApp.close();
    }
  };

  useEffect(() => {
    if (!warnClients && user != null) {
      getWarnClients({ managerId: user!.id })
        .unwrap()
        .then((res) => {
          setWarnClients(res);
        })
        .catch(() => {
          handleApiError();
        });
    }
  }, []);

  useEffect(() => {
    if ((userChanged || props.needRefresh) && user != null) {
      getWarnClients({ managerId: user!.id })
        .unwrap()
        .then((res) => {
          setWarnClients(res);
        })
        .catch(() => {
          handleApiError();
        });
    }
  }, [props.needRefresh]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (user === null) return;
      let newClients: WarnClientsResponse = await getWarnClients({
        managerId: user.id,
      }).unwrap();

      if (newClients && newClients.clientsData && newClients.clientsData.length > 0) {
        setWarnClients(newClients);
      }
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, [warnClients]);

  return (
    <Flex className={'warn-chats'}>
      {warnClients?.clientsData.map((client) => {
        return (
          <WarnChat
            key={'warnchat-id-' + client.chatId}
            enterTgChat={enterTgChat}
            clientId={client.clientId}
            chatId={client.chatId}
            clientName={client.clientName}
            hoursCount={client.hoursCount}
            warnLevel={client.warnLevel}
            isAnswerWait={client.isAnswerWait}
            isPayWait={client.isPayWait}
            isNewClient={client.isNewClient}
          ></WarnChat>
        );
      })}
    </Flex>
  );
};
function handleApiError() {
  throw new Error('Function not implemented.');
}
