import { createApi } from '@reduxjs/toolkit/query/react';
import { FullChatsResponse, LoginRequest, LoginViaTgRequest, UserResponse, WarnClientsResponse } from './types';
import { API_ROUTES } from './constants';
import { baseQueryWithLogout } from '@api/baseQuery';

export const apiClient = createApi({
  reducerPath: 'adminApi',
  baseQuery: baseQueryWithLogout,
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: API_ROUTES.getLoginRoute(),
        method: 'POST',
        body: credentials,
      }),
    }),
    loginTg: builder.mutation<UserResponse, LoginViaTgRequest>({
      query: (credentials) => ({
        url: API_ROUTES.getLoginTgRoute(),
        method: 'POST',
        body: credentials,
      }),
    }),
    enterChat: builder.mutation<void, { chatId: number; managerId: number; newClientId: number }>({
      query: ({ chatId, managerId, newClientId }) => ({
        url: API_ROUTES.enterChat(),
        method: 'POST',
        body: { chatId, managerId, newClientId },
      }),
    }),
    createPayment: builder.mutation<void, { userId: number }>({
      query: ({ userId }) => ({
        url: API_ROUTES.createPayment(),
        method: 'POST',
        body: { userId },
      }),
    }),
    getChatMessagesFull: builder.mutation<
      FullChatsResponse,
      { managerId: number; page?: number; onlyMyChats?: boolean; searchString: string }
    >({
      query: ({ managerId, page, onlyMyChats, searchString }) => ({
        url: API_ROUTES.getFullChatsRoute(),
        method: 'POST',
        body: { managerId, page, onlyMyChats, searchString },
      }),
    }),
    addClientToFavorite: builder.mutation<void, { managerId: number; clientId: number }>({
      query: ({ managerId, clientId }) => ({
        url: API_ROUTES.addClientToFavorite(),
        method: 'POST',
        body: { managerId, clientId },
      }),
    }),
    addChatToMulty: builder.mutation<void, { chatId: number }>({
      query: ({ chatId }) => ({
        url: API_ROUTES.addChatToMulty(),
        method: 'POST',
        body: { chatId },
      }),
    }),
    cancelMessage: builder.mutation<void, { id: number | string }>({
      query: ({ id }) => ({
        url: API_ROUTES.cancelMessage(),
        method: 'POST',
        body: { id },
      }),
    }),
    getWarnClients: builder.mutation<WarnClientsResponse, { managerId: number }>({
      query: ({ managerId }) => ({
        url: API_ROUTES.getWarnClientsRoute(),
        method: 'POST',
        body: { managerId },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginTgMutation,
  useEnterChatMutation,
  useCreatePaymentMutation,
  useGetChatMessagesFullMutation,
  useAddClientToFavoriteMutation,
  useAddChatToMultyMutation,
  useCancelMessageMutation,
  useGetWarnClientsMutation,
} = apiClient;
