import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@app/store';
import { User, UserResponse } from '@api/types';
import { LOCAL_STORAGE, oneWeekMilliseconds } from "@common/constants";

type AuthState = {
  user: User | null;
  token: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<UserResponse>) => {
      state.user = payload.user;
      state.token = payload.access_token;

      localStorage.setItem(LOCAL_STORAGE.USER_CREDENTIALS, JSON.stringify(payload));

      const localUserTimeout = localStorage.getItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT);
      if (!localUserTimeout) {
        localStorage.setItem(
          LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT,
          JSON.stringify(new Date(new Date().getTime() + oneWeekMilliseconds)),
        );
      }
    },
    loggedOut: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, loggedOut } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
