import React, { Component, ErrorInfo } from 'react';
import { border } from '@chakra-ui/react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Обновляем состояние, чтобы следующий рендер показал запасной UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Здесь можно отправлять ошибку на сервер для анализа
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Запасной UI, который будет показан при ошибке
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <hr />
          <b style={{ margin: '0 auto' }}>Что-то пошло не так.</b>
          <hr />
          <button
            style={{ border: '2px solid firebrick', borderRadius: '8px', margin: '4px auto', padding: '4px 8px' }}
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            перезагрузка
          </button>
          <hr />
        </div>
      );
    }

    return this.props.children;
  }
}
