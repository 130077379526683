export const APP_ROUTES = {
  DASHBOARD: '/dashboard',
  CHATS_LIST: '/managerchats',
};

export const LOCAL_STORAGE = {
  USER_CREDENTIALS: 'user-credentials',
  USER_CREDENTIALS_TIMEOUT: 'user-credentials-timeout',
};

export const oneDayMilliseconds = 86400000;
export const oneWeekMilliseconds = 604800000;
