export const API_URL = process.env.REACT_APP_API_URL;
export const API_ROUTES = {
  getLoginRoute: () => `/auth/login`,
  getLoginTgRoute: () => `/auth/login-tg`,
  getUserRoute: () => `/auth/me`,
  getClientsRoute: () => '/admin/clients',
  getChatsRoute: () => '/admin/chats',
  getWarnClientsRoute: () => '/admin/clients/warn-clients',
  getFullChatsRoute: () => '/admin/chats/full',
  getChatMessagesRoute: (id: number) => `/admin/chats/${id}/messages`,
  enterChat: () => 'admin/chats/redirect-manager',
  createPayment: () => 'admin/payment/create',
  addClientToFavorite: () => 'admin/client/add-to-favorite',
  addChatToMulty: () => 'admin/chats/add-to-multy',
  cancelMessage: () => 'admin/chats/cancel-message',
};
