import React, { ChangeEvent } from 'react';
import { Flex } from '@chakra-ui/react';
import { useCreatePaymentMutation } from '@api/apiClient';
import { LOCAL_STORAGE } from '@common/constants';
import { TelegramWebApps } from 'telegram-webapps-types-new';

// @ts-ignore
const tgWebApp: TelegramWebApps.WebApp = window.Telegram.WebApp;

export const ChatHeader = (props: {
  filterMyChats: boolean;
  setterMyFilter: (filterMyChat: boolean) => void;
  setSearchString: (searchString: string) => void;
  setterNeedRefresh: (needRefresh: boolean) => void;
}) => {
  const [createPayment] = useCreatePaymentMutation();

  const createNewPayment = async () => {
    const userId = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_CREDENTIALS)!).user.id;
    await createPayment({
      userId: userId,
    }).unwrap();

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isIframe = window.self !== window.top;
    if (isTouchDevice || isIframe) {
      tgWebApp.close();
    }
  };

  const handleSetterMyFilter = () => {
    props.setterMyFilter(!props.filterMyChats);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    props.setSearchString(e.target.value);
    // props.setterNeedRefresh(true);
  };

  return (
    <Flex className={'chat-header'}>
      <button
        className={['chat-header__btn_all', !props.filterMyChats ? 'chat-header__btn_active' : ''].join(' ')}
        onClick={() => {
          handleSetterMyFilter();
        }}
      >
        Все
      </button>

      <button
        className={['chat-header__btn_my', props.filterMyChats ? 'chat-header__btn_active' : ''].join(' ')}
        onClick={() => {
          handleSetterMyFilter();
        }}
      >
        <div className={['handshake-icon', props.filterMyChats ? 'chat-header__btn_active' : ''].join(' ')} />
        <p>Мои</p>
      </button>

      <input className={'chat-header__search-input'} placeholder={'Поиск'} onChange={(e) => handleChangeSearch(e)} />

      <button
        className={'chat-header__btn_new-payment'}
        onClick={() => {
          createNewPayment();
        }}
      >
        <div className={'credit-card-icon'} />
        <p>Заказы</p>
      </button>

      {/*<button className={'chat-header__btn'}>все чаты</button>*/}

      {/*<button className={'chat-header__btn'}>
        <span className={'icon'}>🔎</span>
      </button>*/}
    </Flex>
  );
};
