import { Box } from "@chakra-ui/react";
import React from "react";

interface MainPageProps {}

const InnerBox = (props: React.PropsWithChildren<MainPageProps>) => {
  return <Box p={3}>{props.children}</Box>;
};

export default InnerBox;
