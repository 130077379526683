import { TelegramWebApps } from 'telegram-webapps-types-new';

// @ts-ignore
const tgWebApp: TelegramWebApps.WebApp = window.Telegram.WebApp;

export const WarnChat = (props: {
  chatId: number;
  clientName: string;
  clientId: number;
  hoursCount: number;
  warnLevel: number;
  isPayWait: boolean;
  isNewClient: boolean;
  isAnswerWait: boolean;
  enterTgChat: (chatId: number, newClientId: number) => any;
}) => {
  const warnDivClass = 'warn-chats__warn_level warn-chats__warn_level_' + props.warnLevel;

  return (
    <button
      onClick={async () => {
        await props.enterTgChat(props.chatId, props.isNewClient ? props.clientId : 0);
      }}
      className={'warn-chats__btn'}
    >
      {props.hoursCount >= 1 ? (
        <div className={warnDivClass}>
          {props.hoursCount < 24 ? Math.floor(props.hoursCount) + 'ч' : Math.floor(props.hoursCount / 24) + 'д'}
        </div>
      ) : null}
      {props.isPayWait === true ? <div className={'credit-card-icon'} /> : null}
      {props.isNewClient === true ? <div className={'flag-icon'} /> : null}
      <p className={'warn-chats__btn_p'}> {props.clientName}</p>
    </button>
  );
};
